import axios, { AxiosError, AxiosResponse } from "axios";
import { ID, Response } from "../../../../../_metronic/helpers";
import { Site, FetchSiteQueryResponse } from "./_models";
// import {Site, UsersQueryResponse} from './_models'
import {
  toast,
  toastConfig,
  ToastOptions,
} from "../../../../utils/toastService";

const API_URL = process.env.REACT_APP_API_URL;
const PROCORE_API_URL = process.env.REACT_APP_PROCORE_API_URL;

export const SITE_URL = `${API_URL}/site`;
export const CREATE_SITE_URL = `${API_URL}/site`;
export const REQUEST_SITES_URL = `${API_URL}/sites`;
export const REQUEST_SITE_URL = `${API_URL}/site`;
export const UPDATE_SITE_URL = `${API_URL}/site`;
export const UPDATE_SITE_IMAGES_URL = `${API_URL}/uploads/images`;
export const DELETE_SITE_URL = `${API_URL}/site`;
export const DELETE_PROCORE_SITE_URL = `${PROCORE_API_URL}/procore/delete-site`;

const createSite = (createSiteFormdata: any) => {
  const createSiteHeaders = {
    "Content-Type": "multipart/form-data",
    "request-id": "site-repo",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Content-Type",
  };
  return axios.post<Site>(CREATE_SITE_URL, createSiteFormdata, {
    headers: createSiteHeaders,
  });
};

const getSites = () => {
  return axios
    .get(REQUEST_SITES_URL)
    .then((d: AxiosResponse<FetchSiteQueryResponse>) => d.data);
};

const getSiteById = async (id: ID) => {
  let result = await axios.get(`${REQUEST_SITE_URL}/${id}`);
  return result.data.result;
};

const updateSite = (siteId: ID, updateSiteFormdata: any) => {
  const updateSiteHeaders = {
    "Content-Type": "multipart/form-data",
    "request-id": "site-repo",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Content-Type",
  };
  return axios.put<Site>(`${UPDATE_SITE_URL}/${siteId}`, updateSiteFormdata, {
    headers: updateSiteHeaders,
  });
};

const updateSiteImages = (updateSiteImageFormdata: any) => {
  const updateSiteHeaders = {
    "Content-Type": "multipart/form-data",
    "request-id": "site-repo",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Content-Type",
  };
  return axios.put<Site>(`${UPDATE_SITE_IMAGES_URL}`, updateSiteImageFormdata, {
    headers: updateSiteHeaders,
  });
};

const deleteSite = async (siteId: ID, projectId?: ID) => {
  try {
    const data = await axios.delete(`${SITE_URL}/${siteId}`);
    if (projectId && data) {
      await deleteProcoreSite(projectId);
    }
    return data;
  } catch (error) {
    const err = error as AxiosError;
    toast.error(err.response?.data?.message, toastConfig as ToastOptions);
  }
};

const deleteProcoreSite = async (projectId: ID) => {
  try {
    const data = await axios.delete(`${DELETE_PROCORE_SITE_URL}/${projectId}`);
    return data;
  } catch (error) {
    const err = error as AxiosError;
    toast.error(err.response?.data?.message, toastConfig as ToastOptions);
  }
};

const getUserById = (id: ID): Promise<Site | undefined> => {
  return axios
    .get(`${SITE_URL}/${id}`)
    .then((response: AxiosResponse<Response<Site>>) => response.data)
    .then((response: Response<Site>) => response.data);
};

const createUser = (user: Site): Promise<Site | undefined> => {
  return axios
    .post(SITE_URL, user)
    .then((response: AxiosResponse<Response<Site>>) => response.data)
    .then((response: Response<Site>) => response.data);
};

const updateUser = (user: Site): Promise<Site | undefined> => {
  return axios
    .put(`${SITE_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<Site>>) => response.data)
    .then((response: Response<Site>) => response.data);
};

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${SITE_URL}/${userId}`).then(() => {});
};

const deleteSelectedSites = (userIds: Array<ID>): Promise<void> => {
  console.log(userIds);
  const requests = userIds.map((id) => axios.delete(`${SITE_URL}/${id}`));
  // const requests = userIds.map((id) => axios.delete(`${SITE_URL}/${id}`));
  return axios.all(requests).then(() => {});
};

export {
  createSite,
  getSites,
  getSiteById,
  deleteUser,
  deleteSelectedSites,
  getUserById,
  createUser,
  updateUser,
  updateSite,
  updateSiteImages,
  deleteSite,
  deleteProcoreSite,
};
