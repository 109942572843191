/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import Alert from "../../components/Alert";
import { unHashLicense } from "../../utils/hashing";

const DashboardPage: FC = () => (
  <div className="d-flex justify-content-start align-items-start vh-100 bg-light">
    <div className="d-block">
      <h1 className="display-1 fw-bold mb-3" style={{ color: "#3b3b64" }}>
        Welcome to FinishPro
      </h1>
      <p className="lead text-muted">
        Your all-in-one solution for project management and productivity.
      </p>
    </div>
  </div>
);

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  const unhashLicense = unHashLicense();
  return (
    <>
      {/* Show expired alert after license status expired : starts */}
      {unhashLicense?.status === "expired" ? (
        <Alert
          alertMessage={
            "License Expired! You have a extension period of " +
            unhashLicense.license.extension_days +
            " days"
          }
          alertType="warning"
        />
      ) : null}
      {/* Show expired alert after license status expired : ends */}
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>
      <DashboardPage />
    </>
  );
};

export { DashboardWrapper };
